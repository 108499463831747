import React, { memo, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { storyblokEditable, SbBlokData } from 'gatsby-source-storyblok';
import { Box, Button, ThemeContext } from 'grommet';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { GET_COLLECTION_PAINT_BUY } from '../../queries/collectionQueries';
import { shopifyClient } from '../../gatsby-theme-apollo/client';
import deNodify from '../../lib/deNodify';
import {
  findOneGalVariant,
  findSampleVariant,
  getProductPath,
} from '../../lib/product';
import { findInteriorStandardShopifyProduct } from '../../lib/product';
import ButtonAddToCart from '../ButtonAddToCart';
import { SAMPLE_ADDED } from '../../lib/analytics/segmentActions';
import { track } from '../../lib/analytics';
import { useDispatch } from 'react-redux';
import { activateProductVariantSelector } from '../../state/product/productSlice';
import formatCurrency from '../../lib/formatCurrency';
import IconAdd from '../Icons/IconAdd';
import useMobile from '../useMobile';
import SmartLink from '../SmartLink';
import IconArrow from '../Icons/IconArrow';

type Props = {
  blok: SbBlokData;
  product: any;
  paintCollection: any;
};

const ResponsiveButton = styled(Button)`
  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    padding: ${(p) => parseInt(p.theme.global.edgeSize.small) * 1.5}px;
  }
`;

const DynamicPaintCartButtons = ({
  blok,
}: // product,
// relatedCollections,
// similarCollections,
// relatedProducts,
Props) => {
  const {
    paintCollection,
    direction = 'row-responsive',
    defaultBorders = false,
    buttonFlex = false,
    useIcon = false,
    enableSample = false,
  } = blok;
  const dispatch = useDispatch();
  const isMobile = useMobile();

  const { data: collectionData, loading } = useQuery(GET_COLLECTION_PAINT_BUY, {
    client: shopifyClient,
    variables: {
      id: paintCollection?.item?.shopifyId,
    },
    skip: !paintCollection?.item?.shopifyId,
  });

  const products = deNodify(collectionData?.collection?.products?.edges).map(
    (p) => ({
      ...p,
      variants: deNodify(p.variants.edges),
      images: deNodify(p.images.edges),
    })
  );

  const product = findInteriorStandardShopifyProduct(products);
  const sample = findSampleVariant(product?.variants);
  const oneGallonVariant = useMemo(
    () => findOneGalVariant(product?.variants ?? []),
    [product?.variants]
  );

  const collection = collectionData?.collection;

  const selectorOptions = {
    paintCollectionId: collection?.id,
    variantId: oneGallonVariant?.id,
    productType: product?.productType,
    altProducts: products,
    paintCollection: collection,
  };

  const handleAddProduct = () => {
    dispatch(activateProductVariantSelector(selectorOptions));
  };

  if (!collection) {
    return null;
  }

  return (
    <Box {...storyblokEditable(blok)}>
      {loading ? (
        <Box>Loading...</Box>
      ) : (
        <Box direction={direction} gap={isMobile ? 'medium' : 'none'}>
          <Box flex={buttonFlex}>
            {enableSample ? (
              <Button
                size="small"
                label={'Add Paint'}
                reverse
                fill
                icon={<IconAdd />}
                justify="between"
                onClick={handleAddProduct}
                secondary={true}
              />
            ) : (
              <SmartLink
                to={getProductPath(product)}
                state={{ collection }}
                style={{
                  display: 'block',
                  height: '100%',
                  textDecoration: 'none',
                }}
              >
                <Button
                  size="small"
                  secondary
                  label="Details"
                  fill
                  icon={<IconArrow direction="right" size="small" />}
                  reverse
                  justify="between"
                />
              </SmartLink>
            )}
          </Box>
          <Box flex={buttonFlex}>
            {sample && (
              <>
                {enableSample ? (
                  <ButtonAddToCart
                    variantId={sample.id}
                    fill
                    label={`${formatCurrency(sample.price)} Sample`}
                    useIcon={true}
                    size="small"
                    style={{ whiteSpace: 'nowrap' }}
                    onAdded={() =>
                      track(SAMPLE_ADDED, {
                        product: product?.title,
                        pdp: location.pathname !== '/paint',
                        cdp: location.pathname === '/paint',
                        location,
                      })
                    }
                  />
                ) : (
                  <Button
                    size="small"
                    label={'Add Paint'}
                    reverse
                    fill
                    icon={<IconAdd />}
                    justify="between"
                    onClick={handleAddProduct}
                    primary={true}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

DynamicPaintCartButtons.propTypes = {
  blok: PropTypes.shape({
    limit: PropTypes.number,
  }),
  collection: PropTypes.object,
  product: PropTypes.object,
  relatedProducts: PropTypes.array,
  relatedCollections: PropTypes.array,
  similarCollections: PropTypes.array,
  relatedPaintCollections: PropTypes.array,
  relatedColorProducts: PropTypes.array,
};

export default memo(DynamicPaintCartButtons);
